<template>
  <div class="admin-edit-form">
    <el-form
      ref="form"
      :model="formData"
      :rules="rules"
      label-width="240px"
      label-suffix=":"
      size="mini"
      @submit.prevent.native="submit"
    >
      <el-form-item
        class="admin-name m-0"
        :label="$t('user.admin')"
        prop="firstName"
      >
        <div>{{ formData.firstName }} {{ formData.lastName }}</div>
      </el-form-item>

      <el-form-item class="m-0" :label="$t('user.email')" prop="email">
        <el-link icon="el-icon-postcard" type="primary">
          {{ formData.email }}
        </el-link>
      </el-form-item>

      <el-form-item
        class="m-0"
        :label="$t('user.subdivision')"
        prop="subdivision"
      >
        <div>{{ get(formData, 'subdivision.name') }}</div>
      </el-form-item>

      <el-form-item
        :label="$t('user.position')"
        prop="position"
      >
        <div>{{ get(formData, 'position.name') }}</div>
      </el-form-item>

      <el-form-item
        :label="$t('user.oldPassword')"
        prop="oldPassword"
      >
        <el-input
          show-password
          type="password"
          autocomplete="off"
          v-model="formData.oldPassword"
        />
      </el-form-item>

      <el-form-item
        :label="$t('user.newPassword')"
        prop="newPassword"
      >
        <el-input
          show-password
          type="password"
          autocomplete="off"
          v-model="formData.newPassword"
        />
      </el-form-item>

      <el-form-item
        :label="$t('user.newPasswordConfirm')"
        prop="newPasswordConfirm"
      >
        <el-input
          show-password
          type="password"
          autocomplete="off"
          v-model="formData.newPasswordConfirm"
        />
      </el-form-item>

      <el-row justify="center" class="mt-4">
        <el-button @click="$emit('close')">
          {{ $t('buttons.close') }}
        </el-button>

        <el-button
          type="primary"
          native-type="submit"
          :disabled="isEqual"
        >
          {{ $t('buttons.save') }}
        </el-button>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import {
  env,
  get,
  cloneDeep,
  isEqual,
  notify,
  getValidationFor,
} from '@/services'

export default {
  name: 'AdminEditForm',

  data({ $store }) {
    return {
      isEqual: true,
      isLoading: false,
      formData: cloneDeep($store.getters['auth/admin']),
      rules: getValidationFor(this, 'user'),
    }
  },

  computed: {
    admin() {
      return this.$store.getters['auth/admin']
    },

    img() {
      const img = get(this, 'info.corporation.image')
      return img ? `${env.FS_BASE_URL}/${img}` : null
    },
  },

  watch: {
    formData: {
      deep: true,
      handler() {
        this.isEqual = isEqual(this.formData, this.admin)
      },
    },
  },

  methods: {
    get,

    submit() {
      this.$refs.form.validate(async isValid => {
        if (!isValid) {
          return false
        }

        this.isLoading = true

        const formData = this.getClear(this.formData)

        try {
          await this.$store.dispatch('admin/update', formData)

          notify({
            type: 'success',
            message: this.$t('titles.changesApplied'),
          })

          this.$emit('updated')
        } catch (e) {
          console.error(e)
        } finally {
          this.isLoading = false
        }
      })
    },

    getClear(rawFormData) {
      return Object
        .entries(rawFormData)
        .reduce((acc, [key, value]) => (isEqual(this.admin[key], value)
          ? acc
          : { ...acc, [key]: value }), {})
    },
  },
}
</script>

<style scoped lang="scss">
.admin-edit-form {
  display: flex;
  flex-direction: column;

  .admin-name * {
    font-size: 1.2rem!important;
  }
}
</style>
